.page {
    opacity: 0;
    transform: translateY(50px);
    animation: fadeInUp 0.5s forwards;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .page-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #1a356e;
    text-shadow: 1px 1px 2px #fff;
  }
  
  .react-calendar {
    border: none;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
  }
  
  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .react-calendar__tile {
    max-width: initial !important;
    color: #000;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    position: relative;
    z-index: 0;
    overflow: hidden;
  }
  
  .react-calendar__tile:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(63, 122, 246, 0.6), rgba(52, 232, 158, 0.6));
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .react-calendar__tile--now {
    background-color: #3f7af6;
    color: #fff;
    z-index: 1;
  }
  
  .react-calendar__tile--active {
    background-color: #34e89e;
    color: #fff;
    transform: scale(1.1);
    z-index: 1;
  }
  
  .react-calendar__tile--disabled {
    background-color: #ccc;
    color: #fff;
    cursor: not-allowed;
    pointer-events: none;
    position: relative;
    z-index: 0;
  }
  
  .react-calendar__tile--disabled:hover:after {
    opacity: 0 !important;
  }
  
  .react-calendar__tile:hover:not(.react-calendar__tile--disabled) {
    background-color: #12c2e9;
    color: #fff;
    transform: scale(1.05);
    z-index: 1;
  }
  
  .react-calendar__tile:hover:not(.react-calendar__tile--disabled):after {
    opacity: 1;
  }