.page {
    opacity: 0;
    transform: translateY(50px);
    animation: fadeInUp 0.5s forwards;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .page-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #1a356e;
    text-shadow: 1px 1px 2px #fff;
  }
  
  .service-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
  }
  
  .service-item {
    margin-bottom: 15px;
    width: auto;
  }
  
  .service-item label {
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .service-item input[type="radio"] {
    margin-right: 10px;
    accent-color: #34e89e;
  }
  
  .service-item:hover label {
    color: #34e89e;
    text-shadow: 0 0 10px rgba(52, 232, 158, 0.5);
  }