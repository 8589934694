body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f2f6fb;
}

.landing-page {
  text-align: center;
  padding: 20px 0px 20px 0px;
}

.progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  padding: 20px 0;
  gap: 20px;
}

.progress-step {
  position: relative;
  cursor: pointer;
  width: 80px;
  height: 80px;
  background-color: #3f7af6;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin: 0 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transform: perspective(800px) rotateX(15deg);
  background: linear-gradient(135deg, #3f7af6 0%, #34e89e 100%);
}

.progress-step.active {
  background-color: #1a356e;
  transform: perspective(800px) rotateX(0);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  background: linear-gradient(135deg, #1a356e 0%, #12c2e9 100%);
}

.progress-step .icon {
  color: #f2f6fb;
  filter: drop-shadow(2px 4px 6px black);
}

.progress-connector {
  position: absolute;
  top: 50%;
  left: 100%;
  width: 40px;
  height: 6px;
  background-color: #3f7af6;
  transform: translateY(-50%);
  z-index: -1;
  border-radius: 3px;
  transition: background-color 0.3s ease-in-out, width 0.5s ease;
}

.progress-connector.active {
  background-color: #12c2e9;
  width: 50px;
}

.page-container {
  padding: 30px 0px 30px 0px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  width: 70%;
  margin-left: 15%;
}

.page-enter {
  opacity: 1;
  transform: translateX(0);
}

.page-leave {
  opacity: 0;
  transform: translateX(-100%);
}

.page {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.next-button {
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #3f7af6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.next-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.next-button:hover:not(:disabled) {
  background-color: #1a356e;
  transform: scale(1.05);
}
.link-5, .link-9, .link-10,.link-11, .link-12, .link-13 {
  color: rgb(0,0,0);
  text-decoration: none;
  transition: color 0.3s ease;
}

.link-5:hover, .link-9:hover, .link-10:hover, .link-11:hover, .link-12:hover, .link-13:hover {
  color: rgb(63, 122, 246); /* Blue color on hover */
}
@media screen and (max-width: 550px) {
  .page-container {
    width: 90%;
    margin-left: 5%;
  }
}
