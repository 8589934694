.containerpay {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    height: auto;
  }
  
  .left-column, .right-column {
    flex: 1;
    margin: 10px;
    max-width: 45%;
  }
  
  .left-column {
    padding-right: 20px;
    position: relative;
    max-height: 40vh;
  }
  
  .inputs-container {
    max-height: calc(100% - 60px); /* Adjust according to the height of h2 */
    overflow-y: auto;
    padding-right: 10px;
    padding-left: 10px;
    scrollbar-width: thin;
    scrollbar-color: #3f7af6 #e0e0e0; /* Firefox */
  }
  
  .inputs-container::-webkit-scrollbar {
    width: 12px; /* Chrome, Safari, Opera */
  }
  
  .inputs-container::-webkit-scrollbar-thumb {
    background: #3f7af6;
    border-radius: 10px;
    border: 3px solid #e0e0e0;
  }
  
  .inputs-container::-webkit-scrollbar-track {
    background: #e0e0e0;
    border-radius: 10px;
  }
  
  .inputs-container:hover::-webkit-scrollbar-thumb {
    background: #1a356e;
  }
  
  .left-column h2, .right-column h2 {
    text-align: center;
    color: #3f7af6;
    margin-bottom: 20px;
    text-transform: uppercase;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 10px;
  }
  
  .input-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .input-group label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .input-group input, .input-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
  }
  
  .input-group input:focus, .input-group select:focus {
    border-color: #3f7af6;
    outline: none;
  }
  
  .right-column p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #666;
  }
  
  .payment-container {
    margin-top: 20px;
    width: 100%;
  }
  
  .payment-button {
    margin-top: 20px;
    padding: 12px 24px;
    background-color: #3f7af6;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .payment-button:hover {
    background-color: #1a356e;
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  
    .left-column, .right-column {
      max-width: 100%;
      padding: 0;
      border: none;
    }
  
    .left-column {
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;
    }
  
    .right-column {
      padding-top: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    .containerpay {
      height: auto;
      flex-direction: column;
    }
    .left-column {
      overflow-y: scroll;
    }
  }