:root {
  --katadent-szürke: rgba(51, 51, 51, .7);
  --katadent-világos-kék: #3898ec;
  --black: black;
  --katadent-sötét-kék: #3f7af6;
  --white: white;
}

p {
  margin-bottom: 0;
}

.herosection {
  border-top: 1px solid #dde5ed;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.herobal {
  background-color: #fff;
  justify-content: flex-start;
  align-items: center;
  width: 55%;
  padding-top: 0;
  padding-left: 10em;
  display: flex;
  position: relative;
}

.herojobb {
  width: 45%;
  position: relative;
}

.nav {
  background-color: #fff;
  flex-flow: column;
  padding-top: 20px;
  padding-left: 10em;
  padding-right: 10em;
  display: flex;
}

.contentholder {
  z-index: 1;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  margin-top: -7em;
  display: flex;
  position: relative;
  padding-right: 20px;
}

.heading-2 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 3rem;
  font-weight: 700;
  backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-font-smoothing: antialiased;
}

.paragraph {
  color: var(--katadent-szürke);
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1;
}

.image {
  width: auto;
  max-width: none;
  height: 100%;
  overflow: visible;
}

.button {
  background-color: var(--katadent-világos-kék);
  text-transform: none;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 60%;
  padding: 20px;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 400;
  display: flex;
}

.kapcsolatstbsection {
  z-index: 99999999;
  background-color: #fff;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
  height: auto;
  padding: 40px 7em 40px 7rem;
  display: flex;
  position: absolute;
  transform: translate(0, -138px);
  box-shadow: 0 0 9px 3px rgba(0, 0, 0, .2);
}

.kapcsolatdiv {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  display: flex;
}

.kkekkortelefon {
  background-color: #3898ec;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
  overflow: hidden;
}

.kapcsolatheading {
  margin-top: 0;
  margin-bottom: 10px;
  font-family: Lovelo, sans-serif;
  font-size: 24px;
  line-height: 1;
}

.kapcsolatleiras {
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.telefonszam {
  color: rgba(51, 51, 51, .7);
  margin-bottom: 5px;
  font-family: Lato, sans-serif;
  font-size: 16px;
}

.telefonicon {
  max-width: 50%;
  height: 50%;
}

.nyitvatartasdiv {
  z-index: 1;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  display: flex;
  position: relative;
}

.kekkorora {
  background-color: #3898ec;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
  overflow: hidden;
}

.ora {
  max-width: 50%;
  height: 50%;
}

.email {
  color: rgba(51, 51, 51, .7);
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 16px;
}

.nyitvatartasheading {
  margin-top: 0;
  font-family: Lovelo, sans-serif;
  font-size: 24px;
  line-height: 1;
}

.pentekinyitva {
  color: rgba(51, 51, 51, .7);
  margin-bottom: 5px;
  font-family: Lato, sans-serif;
  font-size: 16px;
}

.szerdainyitva {
  color: rgba(51, 51, 51, .7);
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 16px;
}

.szolgaltatas {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  display: flex;
}

.section-1 {
  background-color: #f2f6fb;
  width: 100vw;
  height: 50vw;
  margin-top: 7vw;
  display: flex;
}

.rolunkbal {
  justify-content: flex-start;
  align-items: center;
  width: 55%;
  padding-top: 0;
  padding-left: 10em;
  display: flex;
}

.rolunkjobb {
  z-index: 1;
  justify-content: flex-end;
  align-items: flex-end;
  width: 45%;
  padding-top: 5em;
  display: flex;
  position: relative;
  overflow: hidden;
}

.rolunkgomb {
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 20px;
  font-family: Lato, sans-serif;
  font-size: 20px;
  display: flex;
}

.rolunkkep {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.rolunkheroheading {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 2.2rem;
  line-height: 1.3;
}

.rolunkleiras {
  color: rgba(51, 51, 51, .7);
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  line-height: 1.2;
}

.section-2 {
  width: 100vw;
  height: 60vh;
  display: flex;
}

.content {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.div-block-2 {
  background-color: #3898ec;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
}

.image-2 {
  max-width: 50%;
  height: 50%;
}

.heading-5 {
  margin-top: 0;
  margin-bottom: 10px;
  font-family: Lovelo, sans-serif;
  font-size: 24px;
  line-height: 1;
}

.div-block-3 {
  margin-bottom: 10px;
}

.text-block-2, .text-block-3, .text-block-4 {
  color: rgba(51, 51, 51, .7);
  margin-bottom: 10px;
  font-family: Lato, sans-serif;
  font-size: 16px;
}

.text-block-5 {
  z-index: 2;
  color: rgba(51, 51, 51, .7);
  margin-bottom: 10px;
  font-family: Lato, sans-serif;
  font-size: 16px;
}

.body {
  background-color: #f2f6fb;
}

.text-block-6 {
  color: rgba(51, 51, 51, .7);
  font-family: Lato, sans-serif;
  font-size: 16px;
}

.miertmibal {
  width: 55%;
  position: relative;
  overflow: hidden;
}

.miertmijobb {
  justify-content: center;
  align-items: center;
  width: 45%;
  padding-left: 2em;
  padding-right: 2em;
  display: flex;
  position: relative;
}

.div-block-6 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
}

.div-block-7 {
  background-color: #3f7af6;
  width: 30px;
  height: 4px;
  margin-bottom: 4px;
}

.text-block-7 {
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.5rem;
  line-height: 1;
  align-items: flex-end;
    display: flex;
    justify-content: center;
    min-height: 24px;
}

.text-block-8 {
  color: #3898ec;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 2rem;
  line-height: 1;
  display: inline-block;
}

.div-block-8 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
}

.div-block-9 {
  background-color: #3898ec;
  width: 30px;
  height: 4px;
  margin-bottom: 7px;
}

.miertmikep {
  width: 110%;
  max-width: none;
  height: auto;
}

.miertmitext {
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.5rem;
}

.miertmiheading {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 2.2rem;
  line-height: 1.3;
}

.miertmigomb {
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 20px;
  font-family: Lato, sans-serif;
  font-size: 20px;
  display: flex;
}

.miertmitextholder {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
}

.miertmicsik {
  background-color: #3f7af6;
  width: 30px;
  height: 4px;
  margin-bottom: 2px;
}

.miertmileiras {
  color: rgba(51, 51, 51, .7);
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  line-height: 1.2;
}

.section2balkep {
  color: #57a9e3;
  -webkit-text-stroke-color: #57a9e3;
  width: 20%;
  max-width: 20%;
}

.section2midkep, .section2jobbkep {
  width: 20%;
  max-width: 20%;
}

.section2jobbtext, .section2baltext {
  text-align: center;
  width: 80%;
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.2;
}

.section2mid {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  background-color: #fff;
  border-top-left-radius: 30%;
  border-top-right-radius: 30%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
}

.section2content {
  width: 100%;
  height: 60vh;
  display: flex;
}

.section2jobbheading, .section2midheading {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
}

.section2balheading {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
}

.section2bal {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  background-color: #fff;
  border-top-left-radius: 30%;
  border-top-right-radius: 30%;
  border-bottom-left-radius: 30%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 5em;
  padding-bottom: 5em;
  display: flex;
}

.section2jobb {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  background-color: #fff;
  border-top-left-radius: 30%;
  border-top-right-radius: 30%;
  border-bottom-right-radius: 30%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
}

.section3container {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 5em 10em;
  display: flex;
}

.section2heading {
  text-align: center;
  font-family: Lovelo, sans-serif;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.1;
}

.section2midtext {
  text-align: center;
  width: 80%;
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 1.3rem;
  line-height: 1.2;
}

.section-4 {
  background-color: #3898ec;
  border-radius: 20px;
  width: 100%;
  height: 60vh;
  margin-bottom: 5em;
  display: flex;
  overflow: hidden;
}

.section4bal {
  justify-content: center;
  align-items: center;
  width: 55%;
  padding-top: 5em;
  padding-bottom: 5em;
  padding-left: 10em;
  display: flex;
}

.section4jobb {
  width: 45%;
  position: relative;
}

.miertmitext-copy {
  color: #fff;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.5rem;
}

.miertmiheading-copy {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 2.2rem;
  line-height: 1.3;
}

.miertmileiras-copy {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  line-height: 1.2;
}

.miertmigomb-copy {
  color: #3898ec;
  background-color: #fff;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 10px 20px;
  font-family: Lato, sans-serif;
  font-size: 20px;
  display: flex;
}

.contentholder-copy {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  display: flex;
}

.image-4 {
  width: 110%;
  max-width: none;
  height: auto;
  overflow: hidden;
}

.link-5 {
  color: #000;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
}

.footercontainer {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  background-color: #fff;
  border-top-left-radius: 40%;
  border-top-right-radius: 40%;
  flex-flow: column;
  padding-top: 15em;
  padding-left: 5em;
  padding-right: 5em;
  display: flex;
}

.link-9 {
  color: #000;
  font-size: 1rem;
  text-decoration: none;
}

.text-block-14 {
  color: #000;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  display: inline-block;
}

.text-block-13 {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.2;
}

.link-8 {
  color: #000;
  font-family: Satoshi, sans-serif;
  font-size: 1rem;
  text-decoration: none;
}

.footerjobb1 {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-flow: column;
  display: flex;
}

.location {
  height: 100%;
  font-size: 1rem;
  font-weight: 500;
}

.footercontent {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.image-5 {
  max-width: 10%;
}

.link-10 {
  color: #000;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  text-decoration: none;
}

.footercontent2links {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
  align-items: center;
  font-family: Lato, sans-serif;
  display: flex;
}

.text-block-15 {
  color: #000;
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.emaillink {
  justify-content: flex-start;
  align-items: center;
  width: auto;
  display: flex;
}

.footerlicensz {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.hivjonminket {
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.footertextholder {
  width: 40%;
}

.kapcsolat {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.footer-section {
  background-color: rgba(0, 0, 0, 0);
}

.footerjobb {
  justify-content: space-between;
  align-items: flex-start;
  width: 50%;
  display: flex;
}

.footercontent2 {
  border-bottom: 1px solid #000;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  display: flex;
}

.footerheading {
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  display: inline-block;
}

.text-block-12 {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.telcsofooter {
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.footerbal {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  width: auto;
  min-width: auto;
  display: flex;
}

.div-block-10 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
}

.hivasgomb {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border: 2px solid #fff;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  text-decoration: none;
  display: flex;
}

.text-block-16 {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 20px;
  text-decoration: none;
}

.div-block-12 {
  width: 30px;
  height: auto;
}

.bold-text {
  font-family: Lato, sans-serif;
  font-weight: 400;
}

.transitiondiv {
  background-color: #f2f6fa;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.body-2 {
  background-color: #f2f6fb;
}

.rolunkpagebal {
  background-color: #fff;
  justify-content: flex-start;
  align-items: center;
  width: 55%;
  padding-top: 0;
  padding-left: 10em;
  display: flex;
}

.r-lunk-section1 {
  background-color: #f2f6fb;
  margin-bottom: 8em;
  padding: 5em 10em 0;
}

.rolunkcontent1 {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  flex-flow: column;
  display: flex;
}

.szoveg1 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  justify-content: center;
  align-items: center;
  height: auto;
  display: flex;
}

.szoveg1bal {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  display: flex;
}

.elotteutanajobb {
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  width: 45%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.szoveg2heading {
  color: #3898ec;
  margin-top: 20px;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 36px;
  line-height: 1;
}

.bold-text-2 {
  color: var(--katadent-szürke);
  font-family: Lato, sans-serif;
  font-size: 17px;
  line-height: 1.6em;
}

.image-6 {
  border-radius: 10px;
  width: auto;
  max-width: none;
  height: 337px;
  overflow: clip;
}

.szoveg2 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
}

.szoveg2jobb {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  display: flex;
}

.szoveg2bal {
  border-radius: 5px;
  width: auto;
  overflow: hidden;
}

.elotteutana {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
  align-items: flex-start;
  height: auto;
  margin-top: 40px;
  display: flex;
  overflow: hidden;
}

.elotteutanabal {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  width: 45%;
  display: flex;
  position: relative;
}

.szoveg3kep {
  border-radius: 10px;
  width: auto;
  max-width: 80%;
  height: auto;
  overflow: clip;
}

.szoveg3heading {
  margin-top: 20px;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 36px;
}

.szoveg1heading {
  color: #3898ec;
  margin-top: 20px;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 36px;
  line-height: 1;
  height: 60px;
    display: flex;
    margin-top: 0px;
    align-items: center;
}

.szoveg1kep {
  border-radius: 10px;
  width: auto;
  max-width: none;
  height: 337px;
  overflow: clip;
}

.szoveg1jobb {
  border-radius: 5px;
  width: auto;
  overflow: hidden;
}

.rolunkkep-copy {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.rolunkjobb-copy {
  z-index: 1;
  justify-content: flex-end;
  align-items: flex-end;
  width: 45%;
  padding-top: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.text-block-17 {
  color: var(--black);
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.section-1-copy {
  background-color: #fff;
  width: 100vw;
  height: 50vw;
  margin-top: 0;
  display: flex;
}

.szolgaltatasaink-wrap {
  width: 100vw;
  height: auto;
}

.mireszamithatsz {
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.szolgi2 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 20px 2% 2%;
  display: flex;
}

.idopontgomb {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 30%;
  font-family: Lato, sans-serif;
  display: flex;
}

.szolgaltatasokmainheading {
  color: #3f7af6;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 3em;
  line-height: 1;
}

.konzultacio {
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.szolgi1 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.fenyrekotoheading {
  color: #3f7af6;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 20px;
  line-height: 1.2;
  display: inline-block;
}

.nevardiv {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.szolgaltatasleiras {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-flow: column;
  display: flex;
}

.contentholder-2 {
  grid-column-gap: 3em;
  grid-row-gap: 3em;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 10em 10em 5em;
  display: flex;
}

.mennyiido {
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.szolgaltatasainkmaintext {
  text-align: center;
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.leiras {
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.szolgaltatasokwrap {
  grid-column-gap: 4em;
  grid-row-gap: 4em;
  flex-flow: column;
  width: 100%;
  display: flex;
}

.fenyrekotoar {
  border: 1px solid #000;
  border-radius: 20px;
  margin-bottom: 0;
  padding: 5px 10px;
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.mennyiideigdiv {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.body-3 {
  background-color: #f2f6fb;
}

.gyokerkezelesheading {
  color: #3f7af6;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 20px;
  line-height: 1.2;
  display: inline-block;
}

.div-block-34 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.elsoketto, .masodikketto {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  display: flex;
}

.eroszakmentesfogszabi, .happysmilelathatatlan, .ideiglenestomes, .fogkoeltavolitashead, .rogzitettkeszulek, .keramiakeszulek, .kezelesiterv, .kivehetokeszulek, .retencioslemez, .lathatatlankezelesiterv {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.navsocialswrap {
  width: 100%;
  margin-bottom: 0;
  display: flex;
}

.contentwrap {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border-bottom: 1px solid #dde5ed;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

.navtelefonszam {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.navemail {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.image-7 {
  width: 25px;
  height: 25px;
}

.text-block-35 {
  color: #3898ec;
  margin-top: 2px;
  font-family: Lovelo, sans-serif;
  font-size: 1rem;
  line-height: 1;
  text-decoration: none;
}

.image-8 {
  width: 25px;
  height: 25px;
}

.text-block-36 {
  color: #3898ec;
  margin-top: 2px;
  font-family: Lovelo, sans-serif;
  font-size: 1rem;
  line-height: 1;
  text-decoration: none;
}

.logocontent {
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
}

.logolink {
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tobbilink {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
  align-items: center;
  font-family: Lovelo, sans-serif;
  display: flex;
}

.div-block-37 {
  color: #fff;
  background-color: #3898ec;
  border-radius: 5px;
  padding: 16px;
  font-family: Lovelo, sans-serif;
}

.text-block-37 {
  font-size: 1rem;
}

.link-11 {
  color: #000;
  font-size: 1rem;
  text-decoration: none;
}

.link-11.w--current {
  font-family: Lovelo, sans-serif;
}

.link-12, .link-13 {
  color: #000;
  font-family: Lovelo, sans-serif;
  font-size: 1rem;
  text-decoration: none;
}

.konzervaloheading {
  justify-content: center;
  align-items: center;
  display: flex;
}

.heading-25 {
  color: var(--black);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  line-height: 1;
  display: inline-block;
}

.konzervaloheadingkorvonal {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 10px 15px 6px;
}

.szolgaltatasoksection1, .szolgaltatasoksection2 {
  grid-column-gap: 3em;
  grid-row-gap: 3em;
  flex-flow: column;
  display: flex;
}

.paradontologiasection2 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.fognyakierzekenyseg {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 45vw;
  padding: 20px 2% 2%;
  display: flex;
}

.paradontologiaheading {
  justify-content: center;
  align-items: center;
  display: flex;
}

.heading-26 {
  color: var(--black);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 24px;
  line-height: 1;
  display: inline-block;
}

.paradontologiakorvonal {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 10px 15px 6px;
}

.szolgaltatasoksection3 {
  grid-column-gap: 3em;
  grid-row-gap: 3em;
  flex-flow: column;
  display: flex;
}

.gyermekfogaszatheading {
  justify-content: center;
  align-items: center;
  display: flex;
}

.gyermekfogaszatkorvonal {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 10px 15px 6px;
}

.heading-27 {
  color: var(--black);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 24px;
  line-height: 1;
}

.szolgi1-slatt {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 45vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.fogszabalyzassection9 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.szolgaltatasoksection4 {
  grid-column-gap: 3em;
  grid-row-gap: 3em;
  flex-flow: column;
  display: flex;
}

.fogszabalyzasheading {
  justify-content: center;
  align-items: center;
  display: flex;
}

.fogszabalyzaskorvonal {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 10px 15px 6px;
}

.heading-28 {
  color: var(--black);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 24px;
  line-height: 1;
}

.szolgaltatasoksection5 {
  grid-column-gap: 3em;
  grid-row-gap: 3em;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.fogekszerragasztas {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 45vw;
  padding: 20px 2% 2%;
  display: flex;
}

.fogekszerheading {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.fogekszerragasztaskorvonal {
  border: 1px solid #000;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 10px 15px 6px;
  display: flex;
}

.heading-29 {
  color: var(--black);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 24px;
  line-height: 1;
}

.fenyrekototomes {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 50vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.gyokerkezeles {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 50vw;
  padding: 20px 2% 2%;
  display: flex;
}

.gyokertomescsatornankent {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 50vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.ideiglenestom {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 50vw;
  padding: 20px 2% 2%;
  display: flex;
}

.foghuzas {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 50vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.gyogyszerrendeles {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 50vw;
  padding: 20px 2% 2%;
  display: flex;
}

.ideiglenesgyokertomes {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 50vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.fedotomesgyokerkezeles {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 50vw;
  padding: 20px 2% 2%;
  display: flex;
}

.konzervalofogsection1, .konzervalofogsection2, .konzervalofogsection3, .konzervalofogsection4, .paradontologiasection1 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  display: flex;
}

.fogkoeltavolitas {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 50vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.nyalkahartyabetegseg {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 50vw;
  padding: 20px 2% 2%;
  display: flex;
}

.gyermekfog-szatsection1, .gyermekfog-szatsection2 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  display: flex;
}

.barazdazas {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 50vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.elhaltfogkezelses {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 50vw;
  padding: 20px 2% 2%;
  display: flex;
}

.tejfogeltavolitas {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 50vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.tejfogtrepanalas {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 50vw;
  padding: 20px 2% 2%;
  display: flex;
}

.fogszabalyzassection1, .fogszabalyzassection2, .fogszabalyzassection3, .fogszabalyzassection4, .fogszabalyzassection5, .fogszabalyzassection6, .fogszabalyzassection7, .fogszabalyzassection8 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  display: flex;
}

.image-9 {
  width: 50px;
  height: 50px;
}

.heading-30 {
  color: var(--katadent-sötét-kék);
  margin-top: 2px;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 24px;
  line-height: 1;
}

.footerlogo {
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.paragraph-2 {
  color: var(--katadent-szürke);
  font-family: Lato, sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.7;
}

.text-block-38 {
  color: var(--black);
  margin-bottom: 0;
  font-family: Lato, sans-serif;
}

.paragraph-3 {
  font-family: Lato, sans-serif;
}

.image-10 {
  z-index: 11111;
  width: 13%;
  max-width: 50%;
  position: absolute;
  top: 5%;
  bottom: 0%;
  left: 91%;
  right: 0%;
}

.image-11 {
  z-index: 11111;
  position: absolute;
  top: auto;
  bottom: 15%;
  left: 93%;
  right: 0%;
}

.image-12 {
  position: absolute;
  top: 10%;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.hamburger-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: none;
}

.burger-line {
  background-color: #444;
  border-radius: 2px;
  width: 22.5px;
  height: 3px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.telosnav {
  display: none;
}

.burger-4 {
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  display: none;
  position: relative;
}

.burger-bar-wrap {
  flex-direction: column;
  align-items: flex-start;
  display: block;
}

.b4-bar1 {
  background-color: #00f;
  border-radius: 2px;
  width: 18px;
  height: 4px;
}

.b4-bar-2 {
  background-color: #00f;
  border-radius: 2px;
  width: 36px;
  height: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.b4-bar3 {
  float: right;
  background-color: #00f;
  border-radius: 2px;
  width: 18px;
  height: 4px;
}

.navbar-logo-center {
  display: none;
}

.navbar-logo-center-container {
  z-index: 5;
  background-color: rgba(0, 0, 0, 0);
  width: 1030px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.navbar-logo-center-container.shadow-three {
  width: 100%;
  max-width: 1140px;
}

.container {
  width: 100%;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.navbar-wrapper-three {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar-brand-three {
  z-index: 5;
  position: absolute;
}

.nav-menu-wrapper-three {
  width: 100%;
}

.nav-menu-three {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.nav-menu-block {
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.nav-link {
  color: #1a1b1f;
  letter-spacing: .25px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}

.nav-link:hover {
  color: rgba(26, 27, 31, .75);
}

.nav-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-dropdown {
  margin-left: 5px;
  margin-right: 5px;
}

.nav-dropdown-toggle {
  letter-spacing: .25px;
  padding: 5px 30px 5px 10px;
  font-size: 14px;
  line-height: 20px;
}

.nav-dropdown-toggle:hover {
  color: rgba(26, 27, 31, .75);
}

.nav-dropdown-toggle:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-toggle[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-icon {
  margin-right: 10px;
}

.nav-dropdown-list {
  background-color: #fff;
  border-radius: 12px;
}

.nav-dropdown-list.w--open {
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-dropdown-link {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.nav-dropdown-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-link-accent {
  color: #1a1b1f;
  letter-spacing: .25px;
  margin-left: 5px;
  margin-right: 20px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
}

.nav-link-accent:hover {
  color: rgba(26, 27, 31, .75);
}

.button-primary {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #1a1b1f;
  padding: 12px 25px;
  font-size: 12px;
  line-height: 20px;
  transition: all .2s;
}

.button-primary:hover {
  color: #fff;
  background-color: #32343a;
}

.button-primary:active {
  background-color: #43464d;
}

.section-2-copy {
  width: 100vw;
  height: 60vh;
  display: none;
}

.r-lunk-section1-copy {
  background-color: #f2f6fb;
  margin-bottom: 5em;
  padding: 5em 10em 0;
  display: none;
}

.image-16 {
  border-radius: 10px;
  max-width: 82%;
  height: auto;
}

.elotteutanawrap {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.elotteutanaheading {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  line-height: 1;
  display: inline-block;
}

.text-block-42 {
  color: var(--white);
  font-family: Lovelo, sans-serif;
  font-size: 2rem;
  line-height: 1;
  position: absolute;
  top: auto;
  bottom: 2%;
  left: auto;
  right: 2%;
}

.text-block-43 {
  color: var(--white);
  font-family: Lovelo, sans-serif;
  font-size: 2rem;
  line-height: 1;
  position: absolute;
  top: auto;
  bottom: 2%;
  left: 2%;
  right: auto;
}

.text-span {
  color: var(--katadent-világos-kék);
  line-height: 1;
}

.aszfwrap {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-top: 5em;
  padding-left: 0;
  display: flex;
}

.aszfheading1 {
  color: var(--katadent-világos-kék);
  font-family: Lovelo, sans-serif;
  line-height: 1;
  display: inline-block;
}

.heading1container {
  text-align: center;
}

.aszfcontent {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-flow: column;
  width: 100%;
  padding-bottom: 5em;
  padding-left: 10em;
  padding-right: 10em;
  display: flex;
}

.szerzodofelekheading {
  color: var(--katadent-világos-kék);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.3rem;
  line-height: 1;
}

._1-1text {
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

._1-2text2 {
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.nev {
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

.nevstb {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 700;
  display: flex;
}

.szekhely {
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

.emailimpresszum, .honlapimpresszum, .emailaszf {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

._1-content {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.aszftartalma {
  color: var(--katadent-világos-kék);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.3rem;
  line-height: 1;
}

._1-2content {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.cegnev {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

._1-3content {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.heading-31 {
  color: var(--katadent-világos-kék);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.3rem;
  line-height: 1;
}

._1-3text1, ._1-3text2 {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

._2-content {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-flow: column;
  display: flex;
}

.aszfhatalyaheading {
  color: var(--katadent-világos-kék);
  margin-top: 0;
  margin-bottom: 20px;
  font-family: Lovelo, sans-serif;
  font-size: 1.3rem;
  line-height: 1;
}

.aszfhatalya1, .aszfhatalya2, .aszfhatalya3, .aszfhatalya4, .aszfhatalya5, .aszfhatalya6, .aszfhatalya7 {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

._3-content {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.szolgaltatastargyaheading {
  color: var(--katadent-világos-kék);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.3rem;
  line-height: 1;
}

._3-text {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

._3-1content {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.onlinebejelentkezesheading {
  color: var(--katadent-világos-kék);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.3rem;
  line-height: 1;
}

._3-1text {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

._4-content {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.szellemiheading {
  color: var(--katadent-világos-kék);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.3rem;
  line-height: 1;
}

._4-text {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

._5-content {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.adatvedelmiheading {
  color: var(--katadent-világos-kék);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.3rem;
  line-height: 1;
}

._5-text1 {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.3;
}

._5-text2 {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

.adatvedelmirtext {
  color: var(--katadent-világos-kék);
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.3;
  text-decoration: none;
}

._6-content {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.felmondasheading {
  color: var(--katadent-világos-kék);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.3rem;
  line-height: 1;
}

._6-text {
  font-size: 1rem;
  line-height: 1.3;
}

._7-content {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-flow: column;
  display: flex;
}

.vismajorheading {
  color: var(--katadent-világos-kék);
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 1.3rem;
  line-height: 1;
}

._7-text1 {
  margin-bottom: 5px;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

._7-text2 {
  margin-bottom: 5px;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.3;
}

._7-text3, ._7-text4, ._7-text5, ._7-text6 {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

._8-content {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.heading-32 {
  color: var(--katadent-világos-kék);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.3rem;
  line-height: 1;
}

.text-block-45, .text-block-46, .text-block-47, .text-block-48 {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

.text-block-49 {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-style: italic;
  line-height: 1.3;
}

.text-block-50 {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

.body-4 {
  background-color: #f2f6fa;
}

.impresszumcontent {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5em;
  padding-top: 5em;
  display: flex;
}

.heading-33 {
  color: var(--katadent-világos-kék);
  margin-top: 0;
  margin-bottom: 0;
}

.impresszumcontentwrap {
  width: 100%;
  padding-left: 10em;
  padding-right: 10em;
}

.text-block-51 {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

.impresszumwrap {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-top: 5em;
  padding-left: 0;
  display: flex;
}

.honlapotuzemelteto {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

._1-1text-copy {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

._1-2text2-copy {
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.text-block-52 {
  font-size: 1rem;
}

.text-block-53 {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

.elerhetosegiadatok {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}

.elerhetosegiadatokimpresszum {
  color: var(--katadent-világos-kék);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.3rem;
  line-height: 1;
}

.telefonimpresszum {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.kataegyenivalallalkozo {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

.szekhelyimpresszum {
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.adoszamimpresszum {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

.tarhelyszolgaltatoadatai {
  color: var(--katadent-világos-kék);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 1.3rem;
  line-height: 1;
}

.adoszam, .telefon, .emailgma {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

.tarhelyadatok {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.body-5 {
  background-color: #f2f6fa;
}

.potalkatresz {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.happysmileterv {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 20px 2% 2%;
  display: flex;
}

.happysmilefotok {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.happysmilesinek {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 20px 2% 2%;
  display: flex;
}

.eroszakmentesfogszabalyzas {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  width: 45vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.rogzitettretainer {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 20px 2% 2%;
  display: flex;
}

.kivehetoretencios {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.fogszabalyzaseltavolitas {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 20px 2% 2%;
  display: flex;
}

.rogzitettkeszulekaktivalas, .kivehetokeszulekalkalmankent {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.rogzitettkeszulek1fogiv, .kivehetokeszulekek {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 20px 2% 2%;
  display: flex;
}

.kezelesitervkeszites, .onligirozofembracket {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.onligirozokeramia {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 20px 2% 2%;
  display: flex;
}

.rogzitettkeszulekfem {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 20px 2% 2%;
  font-family: Satoshi, sans-serif;
  display: flex;
}

.rogzitettkeszulekkeramia {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 20px 2% 2%;
  display: flex;
}

.paragraph-5 {
  color: var(--katadent-szürke);
  font-family: Lato, sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.7;
}

.gyokerkezelesar {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 5px 10px;
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.gyokertomeshead {
  color: #3f7af6;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 20px;
  line-height: 1.2;
  display: inline-block;
}

.gyokertomesar {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 5px 10px;
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.ideiglenestomesheading {
  color: #3f7af6;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 20px;
  line-height: 1.2;
  display: inline-block;
}

.ideiglenestomesar {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 5px 10px;
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.foghuzasheading {
  color: #3f7af6;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lovelo, sans-serif;
  font-size: 20px;
  line-height: 1.2;
  display: inline-block;
}

.foghuzasar, .gyogyszerrendelesar, .ideiglenesgyokertomesar, .fedotomesar, .fogkoar, .nyalkahartyaar, .fognyakiar, .barazdazasar, .elhalttejar, .tejfoghuzasar, .rogzitettfemar, .rogzitettkeramiaar, .onligirozofemar, .onligirozokeramiaar, .kezelesitervar, .kivehetokeszulekar, .kivehetoaktivalasar, .rogzitettaktivalasar, .rogzitettaktivalas2ivar, .fogszabalyzoeltavoltasar, .kivehetoretenciosar, .rogzitettretainerar, .potalkatreszar, .happysmiletervar, .happysmilefotokar, .happysmilesinar, .eroszakmentesar, .fogekszerar {
  border: 1px solid #000;
  border-radius: 20px;
  margin-bottom: 0;
  padding: 5px 10px;
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

@media screen and (max-width: 991px) {
  .herosection {
    flex-flow: row;
    height: auto;
  }

  .herobal {
    width: 100%;
    height: 50vh;
    padding-top: 3em;
    padding-bottom: 3em;
    padding-left: 2em;
  }

  .herojobb {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .nav {
    padding-left: 2em;
    padding-right: 2em;
  }

  .contentholder {
    justify-content: center;
    align-items: flex-start;
    margin-top: 0;
    padding-top: 0;
  }

  .heading-2 {
    font-size: 40px;
  }

  .paragraph {
    font-size: 20px;
    line-height: 1.3;
  }

  .image {
    width: 100%;
  }

  .kapcsolatstbsection {
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    padding-left: 1em;
    padding-right: 1em;
    position: relative;
    transform: none;
  }

  .kkekkortelefon {
    width: 40px;
    height: 40px;
  }

  .kapcsolatheading {
    font-size: 20px;
    line-height: 30px;
    display: inline-block;
  }

  .kapcsolatleiras {
    justify-content: flex-start;
    align-items: flex-start;
    display: block;
  }

  .telefonszam {
    font-size: 16px;
  }

  .kekkorora {
    width: 40px;
    height: 40px;
  }

  .email {
    font-size: 16px;
  }

  .nyitvatartasheading {
    font-size: 20px;
    display: inline-block;
  }

  .pentekinyitva, .szerdainyitva {
    font-size: 16px;
  }

  .section-1 {
    height: auto;
    margin-top: 0;
    margin-bottom: 2em;
  }

  .rolunkbal {
    width: 45%;
    padding-top: 3em;
    padding-left: 2em;
  }

  .rolunkjobb {
    width: 55%;
    padding-top: 0;
    padding-right: 2em;
  }

  .rolunkgomb {
    width: 40%;
  }

  .rolunkheroheading {
    font-size: 30px;
  }

  .rolunkleiras {
    font-size: 20px;
    line-height: 1.3;
  }

  .section-2 {
    margin-bottom: 2em;
  }

  .div-block-2 {
    width: 40px;
    height: 40px;
  }

  .heading-5 {
    font-size: 20px;
    line-height: 30px;
  }

  .text-block-2, .text-block-3, .text-block-4, .text-block-5, .text-block-6 {
    font-size: 16px;
  }

  .miertmibal {
    padding-left: 2em;
  }

  .miertmijobb {
    justify-content: flex-end;
    align-items: center;
    padding-top: 3em;
  }

  .text-block-7 {
    font-size: 18px;
  }

  .text-block-8 {
    font-size: 26px;
  }

  .miertmitext {
    font-size: 20px;
  }

  .miertmiheading {
    font-size: 30px;
  }

  .miertmigomb {
    width: 40%;
    font-size: 18px;
  }

  .miertmileiras {
    font-size: 20px;
    line-height: 1.3;
  }

  .section2jobbtext, .section2baltext {
    font-size: 15px;
  }

  .section2content {
    height: 50vh;
  }

  .section2jobbheading, .section2midheading, .section2balheading {
    font-size: 20px;
  }

  .section3container {
    padding-top: 2em;
    padding-left: 2em;
    padding-right: 2em;
  }

  .section2heading {
    font-size: 30px;
  }

  .section2midtext {
    font-size: 15px;
  }

  .section-4 {
    padding-left: 2em;
    padding-right: 0;
  }

  .section4bal {
    padding-left: 2em;
  }

  .section4jobb {
    padding-right: 2em;
  }

  .miertmiheading-copy {
    font-size: 30px;
  }

  .miertmileiras-copy {
    font-size: 20px;
    line-height: 1.3;
  }

  .miertmigomb-copy {
    width: 40%;
    font-size: 18px;
  }

  .image-4 {
    height: 100%;
  }

  .link-5 {
    font-size: 16px;
  }

  .footercontainer {
    padding-left: 2em;
    padding-right: 2em;
  }

  .text-block-13 {
    font-size: 16px;
  }

  .footercontent {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    flex-flow: column;
  }

  .link-10 {
    font-size: 16px;
  }

  .footercontent2links {
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .emaillink {
    margin-bottom: 30px;
  }

  .footerlicensz {
    font-family: Lato, sans-serif;
  }

  .hivjonminket {
    font-size: 16px;
  }

  .footertextholder {
    width: 100%;
  }

  .kapcsolat {
    font-size: 16px;
  }

  .footerjobb {
    width: 100%;
    font-family: Lato, sans-serif;
  }

  .footercontent2 {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    flex-flow: column;
    font-family: Lato, sans-serif;
  }

  .text-block-12, .telcsofooter {
    font-size: 16px;
  }

  .footerbal {
    width: 100%;
    font-family: Lato, sans-serif;
  }

  .text-block-16 {
    font-size: 18px;
  }

  .bold-text {
    font-size: 16px;
  }

  .rolunkpagebal {
    padding-left: 2em;
  }

  .r-lunk-section1 {
    padding-left: 2em;
    padding-right: 2em;
  }

  .szoveg1 {
    justify-content: center;
    align-items: flex-start;
    height: auto;
  }

  .elotteutanajobb {
    width: 50%;
  }

  .image-6 {
    height: 400px;
  }

  .szoveg2, .elotteutana {
    height: auto;
  }

  .elotteutanabal {
    width: 50%;
  }

  .szoveg3kep {
    max-width: 100%;
  }

  .szoveg1kep {
    height: 400px;
  }

  .idopontgomb {
    width: 40%;
  }

  .szolgaltatasokmainheading {
    font-size: 3em;
  }

  .contentholder-2 {
    padding-left: 2em;
    padding-right: 2em;
  }

  .image-10, .image-11 {
    display: none;
  }

  .image-13 {
    max-width: 90%;
  }

  .bold-text-3 {
    font-size: 20px;
  }

  .burger-4 {
    z-index: 10000;
    box-shadow: none;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0);
  }

  .b4-bar1, .b4-bar-2, .b4-bar3 {
    background-color: #010266;
  }

  .container {
    max-width: 728px;
  }

  .navbar-wrapper-three {
    justify-content: space-between;
  }

  .navbar-brand-three {
    position: relative;
  }

  .nav-menu-wrapper-three {
    background-color: rgba(0, 0, 0, 0);
    top: 70px;
  }

  .nav-menu-three {
    background-color: #fff;
    border-radius: 50px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    box-shadow: 0 8px 50px rgba(0, 0, 0, .05);
  }

  .nav-link {
    padding-left: 5px;
    padding-right: 5px;
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: absolute;
  }

  .menu-button {
    padding: 12px;
  }

  .menu-button.w--open {
    color: #fff;
    background-color: #a6b1bf;
  }

  .section-2-copy {
    margin-bottom: 2em;
  }

  .r-lunk-section1-copy {
    padding-left: 2em;
    padding-right: 2em;
  }

  .image-16 {
    max-width: 101%;
  }

  .aszfheading1 {
    font-size: 2.5em;
  }

  .aszfcontent {
    padding-left: 2em;
    padding-right: 2em;
  }
}

@media screen and (max-width: 767px) {
  .herosection {
    flex-flow: column;
  }

  .herojobb {
    padding-left: 2em;
    padding-right: 2em;
  }

  .nav {
    padding-left: 1em;
    padding-right: 1em;
    position: relative;
  }

  .contentholder {
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .kapcsolatstbsection {
    padding-left: 2em;
    padding-right: 2em;
  }

  .kapcsolatdiv {
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .kkekkortelefon {
    width: 60px;
    height: 60px;
  }

  .kapcsolatheading {
    text-align: center;
    font-size: 20px;
    display: block;
  }

  .telefonszam {
    text-align: center;
    font-size: 17px;
  }

  .nyitvatartasdiv {
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .kekkorora {
    width: 60px;
    height: 50px;
  }

  .email {
    text-align: center;
    font-size: 17px;
  }

  .nyitvatartasheading {
    text-align: center;
    display: block;
  }

  .pentekinyitva, .szerdainyitva {
    text-align: center;
    font-size: 17px;
  }

  .szolgaltatas {
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .section-1 {
    flex-flow: column;
  }

  .rolunkbal {
    width: 100%;
    padding-bottom: 3em;
  }

  .rolunkjobb {
    width: 100%;
    padding: 0;
  }
  .miertmibal {
    padding: 0;
  }

  .section-2 {
    flex-flow: column;
    height: auto;
  }

  .content {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
  }

  .div-block-2 {
    width: 60px;
    height: 60px;
  }

  .heading-5 {
    text-align: center;
    font-size: 20px;
    line-height: 1;
  }

  .text-block-2, .text-block-3, .text-block-4, .text-block-5, .text-block-6 {
    text-align: center;
    font-size: 17px;
  }

  .miertmibal, .miertmijobb {
    width: 100%;
  }

  .miertmikep {
    width: 100%;
    height: 100%;
  }

  .miertmitext {
    font-size: 18px;
  }

  .miertmiheading {
    font-size: 20px;
  }

  .miertmigomb, .miertmileiras {
    font-size: 16px;
  }

  .section2mid {
    border-radius: 40%;
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .section2content {
    flex-flow: column;
    height: auto;
  }

  .section2bal {
    border-radius: 40%;
    padding-top: 7em;
    padding-bottom: 7em;
  }

  .section2jobb {
    border-radius: 40%;
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .section2heading {
    font-size: 20px;
  }

  .section-4 {
    flex-flow: column;
    height: auto;
    padding-left: 0;
    padding-right: 0;
  }

  .section4bal {
    width: 100%;
  }

  .section4jobb {
    width: 100%;
    padding-right: 0;
  }

  .image-4 {
    width: 100%;
  }

  .footercontainer {
    border-top-left-radius: 20%;
    border-top-right-radius: 20%;
    padding-top: 5em;
    padding-left: 1em;
    padding-right: 1em;
  }

  .link-9, .text-block-14, .text-block-13 {
    font-size: 15px;
  }

  .link-10 {
    text-align: left;
    width: auto;
    font-size: 15px;
  }

  .footercontent2links {
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .text-block-15, .hivjonminket, .kapcsolat, .telcsofooter, .footerbal {
    font-size: 15px;
  }

  .rolunkpagebal {
    width: 100%;
    padding-top: 3rem;
    padding-right: 2em;
  }

  .r-lunk-section1 {
    display: none;
  }

  .rolunkcontent1 {
    width: 100%;
  }

  .szoveg1 {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .szoveg1bal {
    justify-content: center;
    align-items: center;
    width: auto;
  }

  .elotteutanajobb {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .szoveg2 {
    flex-flow: column;
  }

  .szoveg2jobb {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .elotteutana {
    flex-flow: column;
  }

  .elotteutanabal {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .szoveg3kep {
    width: auto;
    max-width: 80%;
  }

  .szoveg1kep {
    width: auto;
    height: 400px;
  }

  .szoveg1jobb {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .rolunkjobb-copy {
    width: 100%;
    padding-left: 2em;
    padding-right: 2em;
  }

  .section-1-copy {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-flow: column;
    height: auto;
  }

  .szolgi2, .szolgi1 {
    width: 100%;
  }

  .contentholder-2 {
    padding-left: 2em;
    padding-right: 2em;
  }

  .contentwrap {
    display: none;
  }

  .logocontent {
    flex-flow: column;
  }

  .logolink {
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  .tobbilink {
    display: none;
  }

  .fognyakierzekenyseg, .szolgi1-slatt, .fogekszerragasztas, .fenyrekototomes, .gyokerkezeles, .gyokertomescsatornankent, .ideiglenestom, .foghuzas, .gyogyszerrendeles, .ideiglenesgyokertomes, .fedotomesgyokerkezeles {
    width: 100%;
  }

  .konzervalofogsection1, .konzervalofogsection2, .konzervalofogsection3, .konzervalofogsection4, .paradontologiasection1 {
    flex-flow: column;
  }

  .fogkoeltavolitas, .nyalkahartyabetegseg {
    width: 100%;
  }

  .gyermekfog-szatsection1, .gyermekfog-szatsection2 {
    flex-flow: column;
  }

  .barazdazas, .elhaltfogkezelses, .tejfogeltavolitas, .tejfogtrepanalas {
    width: 100%;
  }

  .fogszabalyzassection1, .fogszabalyzassection2, .fogszabalyzassection3, .fogszabalyzassection4, .fogszabalyzassection5, .fogszabalyzassection6, .fogszabalyzassection7, .fogszabalyzassection8 {
    flex-flow: column;
  }

  .paragraph-2 {
    text-align: center;
  }

  .hamburger-wrapper {
    display: block;
  }

  .telosnav {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-flow: column;
    width: 100%;
    height: 200px;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    position: relative;
  }

  .telosnavkezdolap, .telosnavrolunk {
    color: #000;
    font-family: Lovelo, sans-serif;
    font-size: 15px;
    text-decoration: none;
  }

  .telosnavszolgi {
    color: var(--black);
    font-family: Lovelo, sans-serif;
    font-size: 15px;
    text-decoration: none;
  }

  .telosnavgomb {
    background-color: var(--katadent-világos-kék);
    color: var(--white);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    width: 30%;
    padding: 10px 15px;
    font-family: Lovelo, sans-serif;
    display: flex;
  }

  .telosnavkspcsolat {
    font-family: Lovelo, sans-serif;
  }

  .telosnavelerhetosegek {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    display: flex;
  }

  .telosnavteloszam {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    color: #000;
    justify-content: center;
    align-items: center;
    font-family: Lovelo, sans-serif;
    font-size: 15px;
    text-decoration: none;
    display: flex;
  }

  .telosnavemail {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    color: var(--black);
    justify-content: center;
    align-items: center;
    font-family: Lovelo, sans-serif;
    font-size: 15px;
    text-decoration: none;
    display: flex;
  }

  .text-block-39, .text-block-40 {
    color: var(--katadent-világos-kék);
    margin-top: 2px;
    line-height: 1;
  }

  .image-14, .image-15 {
    width: 25px;
    height: 25px;
  }

  .burger-4 {
    width: auto;
    height: auto;
    padding: 10px;
    display: block;
    position: relative;
  }

  .b4-bar1, .b4-bar-2, .b4-bar3 {
    background-color: var(--katadent-világos-kék);
  }

  .navbar-logo-center {
    display: block;
  }

  .navbar-brand-three {
    padding-left: 0;
  }

  .nav-menu-three {
    border-radius: 20px;
    flex-direction: column;
    padding-bottom: 30px;
  }

  .nav-menu-block {
    flex-direction: column;
  }

  .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .nav-dropdown {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .nav-dropdown-toggle {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .nav-dropdown-list.shadow-three {
    box-shadow: 0 8px 50px rgba(0, 0, 0, .05);
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: relative;
  }

  .nav-dropdown-list.shadow-three.mobile-shadow-hide {
    box-shadow: none;
  }

  .nav-link-accent {
    margin-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .mobile-margin-top-10 {
    margin-top: 10px;
  }

  .div-block-38 {
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .telosnavlogo {
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .section-2-copy {
    flex-flow: column;
    height: auto;
  }

  .r-lunk-section1-copy {
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2em;
    padding-right: 2em;
    display: flex;
  }

  .paragraph-4 {
    text-align: center;
  }

  .image-16 {
    max-width: 80%;
  }

  .elotteutanaheading {
    font-size: 30px;
  }

  .aszfheading1 {
    font-size: 2em;
  }

  .aszfcontent {
    padding-left: 1em;
    padding-right: 1em;
  }

  .potalkatresz, .happysmileterv, .happysmilefotok, .happysmilesinek, .eroszakmentesfogszabalyzas, .rogzitettretainer, .kivehetoretencios, .fogszabalyzaseltavolitas, .rogzitettkeszulekaktivalas, .kivehetokeszulekalkalmankent, .rogzitettkeszulek1fogiv, .kivehetokeszulekek, .kezelesitervkeszites, .onligirozofembracket, .onligirozokeramia, .rogzitettkeszulekfem, .rogzitettkeszulekkeramia {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .herobal {
    height: auto;
    padding-left: 1em;
  }

  .herojobb {
    padding-left: 1em;
    padding-right: 1em;
  }

  .contentholder {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .heading-2 {
    font-size: 24px;
    line-height: 1.2;
  }

  .paragraph {
    font-size: 15px;
  }

  .button {
    width: 40%;
    padding: 10px 15px;
    font-size: 15px;
  }

  .kapcsolatheading {
    font-size: 18px;
  }

  .telefonszam {
    font-size: 16px;
  }

  .kekkorora {
    height: 60px;
  }

  .email {
    font-size: 16px;
  }

  .nyitvatartasheading {
    font-size: 18px;
  }

  .pentekinyitva, .szerdainyitva {
    font-size: 16px;
  }

  .rolunkbal {
    padding-left: 1em;
    padding-right: 1em;
  }

  .rolunkgomb {
    padding: 10px 15px;
    font-size: 15px;
  }

  .rolunkheroheading {
    font-size: 24px;
    line-height: 1.2;
  }

  .rolunkleiras {
    font-size: 15px;
  }

  .section-2 {
    display: none;
  }

  .heading-5 {
    font-size: 18px;
  }

  .text-block-2, .text-block-3, .text-block-4 {
    font-size: 15px;
  }

  .text-block-5, .text-block-6 {
    font-size: 16px;
  }

  .miertmijobb {
    padding-left: 1em;
    padding-right: 1em;
  }

  .div-block-6 {
    font-size: 18px;
  }

  .text-block-7, .text-block-8, .miertmitext {
    font-size: 17px;
  }

  .miertmiheading {
    font-size: 24px;
  }

  .miertmigomb {
    padding: 10px 15px;
    font-size: 15px;
  }

  .miertmileiras {
    font-size: 15px;
  }

  .section2mid, .section2bal, .section2jobb {
    border-radius: 30%;
  }

  .section3container {
    padding-left: 1em;
    padding-right: 1em;
  }

  .section2heading {
    z-index: 1;
    font-size: 20px;
    position: relative;
  }

  .section4bal {
    padding-left: 1em;
    padding-right: 1em;
  }

  .miertmiheading-copy {
    font-size: 20px;
    line-height: 1.2;
  }

  .miertmileiras-copy {
    font-size: 15px;
  }

  .miertmigomb-copy {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
  }

  .footercontainer {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }

  .text-block-13 {
    text-align: left;
  }

  .footerjobb1, .location {
    width: 100%;
  }

  .link-10 {
    text-align: left;
  }

  .footercontent2links {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    text-align: left;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .emaillink {
    margin-bottom: 0;
  }

  .footerlicensz {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    flex-flow: row;
    padding-bottom: 10px;
  }

  .footerjobb {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-flow: column;
  }

  .footercontent2 {
    padding-bottom: 10px;
  }

  .footerbal {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .hivasgomb {
    padding-left: 15px;
    padding-right: 15px;
    text-decoration: none;
  }

  .text-block-16 {
    font-size: 15px;
    text-decoration: none;
  }

  .bold-text {
    font-size: 15px;
  }

  .transitiondiv {
    display: block;
  }

  .rolunkpagebal {
    padding-left: 1em;
    padding-right: 1em;
  }

  .rolunkcontent1 {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    overflow: hidden;
  }

  .szoveg1bal {
    justify-content: center;
    align-items: flex-start;
  }

  .elotteutanajobb {
    width: 100%;
  }

  .szoveg2heading {
    font-size: 20px;
    line-height: 1;
  }

  .bold-text-2 {
    text-align: left;
    font-size: 15px;
  }

  .image-6 {
    width: auto;
  }

  .szoveg2jobb {
    justify-content: center;
    align-items: flex-start;
  }

  .szoveg3kep {
    max-width: 100%;
  }

  .szoveg3heading {
    font-size: 20px;
    line-height: 1.2;
  }

  .szoveg1heading {
    font-size: 20px;
    line-height: 1;
  }

  .rolunkjobb-copy {
    padding-left: 1em;
    padding-right: 1em;
  }

  .text-block-17 {
    font-size: 15px;
  }

  .szolgi2 {
    padding-bottom: 3%;
    padding-left: 3%;
    padding-right: 3%;
  }

  .idopontgomb {
    width: 50%;
  }

  .szolgaltatasokmainheading {
    font-size: 30px;
  }

  .szolgi1 {
    padding-bottom: 3%;
    padding-left: 3%;
    padding-right: 3%;
  }

  .fenyrekotoheading {
    line-height: 1.7;
  }

  .contentholder-2 {
    padding-left: 1em;
    padding-right: 1em;
  }

  .szolgaltatasainkmaintext {
    font-size: 15px;
  }

  .gyokerkezelesheading {
    line-height: 1.7;
  }

  .heading-25 {
    font-size: 22px;
  }

  .fognyakierzekenyseg {
    padding-bottom: 3%;
    padding-left: 3%;
    padding-right: 3%;
  }

  .heading-26, .heading-27 {
    font-size: 22px;
  }

  .szolgi1-slatt {
    padding-bottom: 3%;
    padding-left: 3%;
    padding-right: 3%;
  }

  .heading-28 {
    font-size: 22px;
  }

  .fogekszerragasztas {
    padding-bottom: 3%;
    padding-left: 3%;
    padding-right: 3%;
  }

  .heading-29 {
    font-size: 22px;
  }

  .fenyrekototomes, .gyokerkezeles, .gyokertomescsatornankent, .ideiglenestom, .foghuzas, .gyogyszerrendeles, .ideiglenesgyokertomes, .fedotomesgyokerkezeles, .fogkoeltavolitas, .nyalkahartyabetegseg, .barazdazas, .elhaltfogkezelses, .tejfogeltavolitas, .tejfogtrepanalas {
    padding-bottom: 3%;
    padding-left: 3%;
    padding-right: 3%;
  }

  .heading-30 {
    font-size: 20px;
  }

  .footerlogo {
    margin-bottom: 15px;
  }

  .paragraph-2 {
    text-align: left;
  }

  .text-block-38, .paragraph-3 {
    font-size: 15px;
  }

  .bold-text-3 {
    font-size: 18px;
  }

  .telosnav {
    display: flex;
  }

  .telosnavgomb {
    max-width: 30%;
  }

  .telosnavteloszam {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    justify-content: center;
    align-items: center;
  }

  .telosnavemail {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .text-block-39, .text-block-40 {
    margin-top: 2px;
    line-height: 1;
  }

  .image-14, .image-15 {
    width: 25px;
    height: 25px;
  }

  .container {
    max-width: none;
  }

  .nav-menu-three {
    flex-direction: column;
  }

  .text-block-41 {
    font-size: 15px;
  }

  .nyitvatartas {
    width: 100%;
  }

  .section-2-copy {
    display: block;
  }

  .r-lunk-section1-copy {
    padding-left: 1em;
    padding-right: 1em;
  }

  .paragraph-4 {
    text-align: left;
    font-size: 15px;
  }

  .image-16 {
    max-width: 100%;
  }

  .elotteutanaheading {
    font-size: 20px;
    line-height: 1;
  }

  .aszfheading1 {
    font-size: 2em;
  }

  .szerzodofelekheading {
    font-size: 1.2rem;
  }

  ._1-1text, ._1-2text2, .nev, .nevstb, .szekhely, .emailimpresszum, .honlapimpresszum, .emailaszf {
    font-size: 15px;
  }

  .aszftartalma {
    font-size: 1.2rem;
  }

  .cegnev {
    font-size: 15px;
  }

  .heading-31 {
    font-size: 1.2rem;
  }

  ._1-3text1, ._1-3text2 {
    font-size: 15px;
  }

  .aszfhatalyaheading {
    font-size: 1.2rem;
  }

  .aszfhatalya1, .aszfhatalya2, .aszfhatalya3, .aszfhatalya4, .aszfhatalya5, .aszfhatalya6, .aszfhatalya7 {
    font-size: 15px;
  }

  .szolgaltatastargyaheading {
    font-size: 1.2rem;
  }

  ._3-text {
    font-size: 15px;
  }

  .onlinebejelentkezesheading {
    font-size: 1.2rem;
  }

  ._3-1text {
    font-size: 15px;
  }

  .adatvedelmiheading {
    font-size: 1.2rem;
  }

  ._5-text1, ._5-text2 {
    font-size: 15px;
  }

  .felmondasheading {
    font-size: 1.2rem;
  }

  ._6-text {
    font-size: 15px;
  }

  .vismajorheading {
    font-size: 1.2rem;
  }

  ._7-text1, ._7-text2, ._7-text3, ._7-text4, ._7-text5, ._7-text6 {
    font-size: 15px;
  }

  .heading-32 {
    font-size: 1.2rem;
  }

  .text-block-45, .text-block-46, .text-block-48, .text-block-49, .text-block-50 {
    font-size: 15px;
  }

  .elerhetosegiadatokimpresszum {
    font-size: 1.2rem;
  }

  .telefonimpresszum {
    font-size: 15px;
    font-weight: 700;
  }

  .kataegyenivalallalkozo, .szekhelyimpresszum, .adoszamimpresszum {
    font-size: 15px;
  }

  .tarhelyszolgaltatoadatai {
    font-size: 1.2rem;
  }

  .adoszam, .telefon, .emailgma {
    font-size: 15px;
  }

  .potalkatresz, .happysmileterv, .happysmilefotok, .happysmilesinek, .eroszakmentesfogszabalyzas, .rogzitettretainer, .kivehetoretencios, .fogszabalyzaseltavolitas, .rogzitettkeszulekaktivalas, .kivehetokeszulekalkalmankent, .rogzitettkeszulek1fogiv, .kivehetokeszulekek, .kezelesitervkeszites, .onligirozofembracket, .onligirozokeramia, .rogzitettkeszulekfem, .rogzitettkeszulekkeramia {
    padding-bottom: 3%;
    padding-left: 3%;
    padding-right: 3%;
  }

  .gyokertomeshead, .ideiglenestomesheading, .foghuzasheading {
    line-height: 1.7;
  }
}


@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Lovelo';
  src: url('../fonts/Lovelo-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
.herosection .transitiondiv {
  transition: opacity 1s ease-in-out, width 1s ease-in-out;
}

.herosection .div-block-8,
.herosection .heading-2,
.herosection .paragraph{
  opacity: 0;
}

.herosection .transitiondiv {
  width: 100%;
}

.herosection .in-view {
  opacity: 1;
}

.herosection .transitiondiv.in-view {
  width: 0%;
}
.kapcsolatstbsection .szolgaltatas,
.kapcsolatstbsection .kapcsolatdiv,
.kapcsolatstbsection .nyitvatartasdiv {
  opacity: 0;
}

.kapcsolatstbsection .in-view {
  opacity: 1;
}
.section-1 .div-block-6,
.section-1 .rolunkheroheading,
.section-1 .rolunkleiras,
.section-1 .transitiondiv {
  opacity: 0;
}
.section-1 .transitiondiv {
  transition: opacity 1s ease-in-out, width 1s ease-in-out;
}

.section-1 .transitiondiv {
  width: 100%;
  opacity: 1; /* Maintain opacity 1 for the transitiondiv */
}

.section-1 .in-view {
  opacity: 1;
}

.section-1 .transitiondiv.in-view {
  width: 0%;
}
.section-2-copy .miertmitextholder,
.section-2-copy .miertmiheading,
.section-2-copy .miertmileiras,
.section-2-copy .miertmigomb.w-button {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.section-2-copy .transitiondiv {
  width: 100%;
  opacity: 1; /* Maintain opacity 1 for the transitiondiv */
  transition: width 1s ease-in-out;
}

.section-2-copy .in-view {
  opacity: 1;
}

.section-2-copy .transitiondiv.in-view {
  width: 0%;
}
.section-2 .miertmitextholder,
.section-2 .miertmiheading,
.section-2 .miertmileiras {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.section-2 .transitiondiv {
  width: 100%;
  opacity: 1; /* Maintain opacity 1 for the transitiondiv */
  transition: width 1s ease-in-out;
}

.section-2 .in-view {
  opacity: 1;
}

.section-2 .transitiondiv.in-view {
  width: 0%;
}
.section-3 .section2heading,
.section-3 .section2content {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.section-3 .in-view {
  opacity: 1;
}
.section-4 .miertmitextholder,
.section-4 .miertmiheading-copy,
.section-4 .miertmileiras-copy,
.section-4 .miertmigomb-copy {
  opacity: 0;
}
.section-4 .miertmigomb-copy {
  transition: opacity 1s ease-in-out;
}

.section-4 .transitiondiv {
  width: 100%;
  opacity: 1; /* Maintain opacity 1 for the transitiondiv */
  transition: width 1s ease-in-out;
}

.section-4 .in-view {
  opacity: 1;
}

.section-4 .transitiondiv.in-view {
  width: 0%;
}
.footer-section {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.footer-section.in-view {
  opacity: 1;
}
.section-1-copy .div-block-6,
.section-1-copy .rolunkheroheading,
.section-1-copy .rolunkleiras {
  opacity: 0;
}

.section-1-copy .transitiondiv {
  width: 100%;
  opacity: 1; /* Maintain opacity 1 for the transitiondiv */
  transition: width 1s ease-in-out;
}

.section-1-copy .in-view {
  opacity: 1;
}

.section-1-copy .transitiondiv.in-view {
  width: 0%;
}
.r-lunk-section1 .szoveg1heading,
.r-lunk-section1 .szoveg2heading,
.r-lunk-section1 .elotteutanaheading {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.r-lunk-section1 .in-view {
  opacity: 1;
}
.footer-section,
.konzervaloheading,
.paradontologiaheading,
.gyermekfogaszatheading,
.fogszabalyzasheading,
.fogekszerheading {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.footer-section.in-view,
.konzervaloheading.in-view,
.paradontologiaheading.in-view,
.gyermekfogaszatheading.in-view,
.fogszabalyzasheading.in-view,
.fogekszerheading.in-view {
  opacity: 1;
}
.div-block-8,
.div-block-6,
.miertmitextholder,
.section2heading,
.elotteutanaheading{
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease, width 1s ease-in-out !important;
}

.div-block-8.in-view,
.div-block-6.in-view,
.miertmitextholder.in-view,
.section2heading.in-view,
.elotteutanaheading.in-view {
  opacity: 1;
  transform: translateY(0);
}

.heading-2,
.rolunkheroheading,
.miertmiheading,
.szoveg1heading,
.konzervaloheading,
.paradontologiaheading,
.gyermekfogaszatheading,
.fogszabalyzasheading,
.fogekszerheading  {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease, width 1s ease-in-out !important;
}

.heading-2.in-view,
.rolunkheroheading.in-view,
.miertmiheading.in-view,
.szoveg1heading.in-view,
.konzervaloheading.in-view,
.paradontologiaheading.in-view,
.gyermekfogaszatheading.in-view,
.fogszabalyzasheading.in-view,
.fogekszerheading.in-view {
  opacity: 1;
  transform: translateX(0);
}
.szoveg2heading {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease, width 1s ease-in-out !important;
}

.szoveg2heading.in-view {
  opacity: 1;
  transform: translateX(0);
}

.paragraph,
.rolunkleiras,
.miertmileiras {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease 0.1s, width 1s ease-in-out !important; /* Delay for a smooth transition */
}

.paragraph.in-view,
.rolunkleiras.in-view,
.miertmileiras.in-view {
  opacity: 1 ; 
  transform: translateX(0);
}
.nyitvatartasdiv div,
.szolgaltatas div,
.kapcsolatdiv div,
.section2content div {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease, width 1s ease-in-out !important;
}
.nyitvatartasdiv.in-view div,
.szolgaltatas.in-view div,
.kapcsolatdiv.in-view div,
.section2content.in-view div  {
  opacity: 1;
  transform: translateY(0);
}
.elotteutanaheading {
  height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}