.page {
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 0.5s forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.date-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.nav-button {
  background-color: #3f7af6;
  border: none;
  color: white;
  font-size: 24px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: 5px;
  margin: 0 20px;
}

.nav-button:hover {
  background-color: #1a356e;
  transform: scale(1.05);
}

.date-display {
  text-align: center;
}

.date-display h1 {
  font-size: 24px;
  color: #1a356e;
}

.date-display h2 {
  font-size: 20px;
  color: #3f7af6;
}

.time-slots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.time-slot {
  padding: 10px 20px;
  background-color: #f2f6fb;
  border: 2px solid #3f7af6;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.time-slot:hover {
  background-color: #3f7af6;
  color: white;
  transform: scale(1.05);
}

.time-slot.selected {
  background-color: #ccc;
  color: white;
  text-decoration: line-through;
  cursor: not-allowed;
}

.time-slot.active {
  background-color: #34e89e;
  color: white;
  transform: scale(1.1);
}

.next-button {
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #3f7af6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.next-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.next-button:hover:not(:disabled) {
  background-color: #1a356e;
  transform: scale(1.05);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modclose-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-input {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.modal-input label {
  font-weight: bold;
}

.modal-input input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button {
  padding: 10px;
  background-color: #3f7af6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-button:hover {
  background-color: #1a356e;
  transform: scale(1.05);
}